<template>
  <div>
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          สรุปยอดโปรโมชั่น
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <!-- Table Function -->
          <b-row class="mb-2">
            <b-col cols="12 col-md-3">
              <div class="mb-1">
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_start_report"> วันที่ (จาก) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_start_report" id="date_start_report" class="form-control text-center" />

                </b-input-group>
              </div>

              <div class="mb-1">
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> เวลา (จาก) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>
            </b-col>
            <b-col cols="12 col-md-3">
              <div class="mb-1">
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> วันที่ (ถึง) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>

              <div class="mb-1">
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> เวลา (ถึง) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>
            </b-col>
            <b-col cols="12 col-md-6">
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-primary waves-effect waves-light mr-1 mb-1"> ค้นหา </b-button>
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-success waves-effect waves-light mr-1 mb-1"> วันนี้ </b-button>
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-info waves-effect waves-light mr-1 mb-1"> เมื่อวาน </b-button>
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-secondary waves-effect waves-light mr-1 mb-1"> สัปดาห์นี้ </b-button>
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-danger waves-effect waves-light mr-1 mb-1"> เดือนนี้ </b-button>
              <b-button @click="this.filter=''" variant="btn-primary" class="btn bg-gradient-warning waves-effect waves-light mr-1 mb-1"> ออกรานงาน Excel </b-button>
            </b-col>
          </b-row>
          <!-- End Table Function -->
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <!-- แถบแรก -->
    <b-card no-body>
      <div class="card-content">
        <b-table
          :fields="fields"
          :items="items"
          responsive
          table-class="table-hover" show-empty>
          <template #empty="">
            <div class="align-items-center text-center">ไม่พบข้อมูล</div>
          </template>
          <template #emptyfiltered="">
            <div class="align-items-center text-center">ไม่พบข้อมูล</div>
          </template>
          <template #cell(deposit)="data">
            {{ toMoney(data.value || 0) }}
          </template>
          <template #cell(bonus)="data">
            {{ toMoney(data.value || 0) }}
          </template>
          <template #cell(all)="data">
            {{ toMoney(data.value || 0) }}
          </template>
          <template #cell(withdraw)="data">
            {{ toMoney(data.value || 0) }}
          </template>
          <template #cell(total)="data">
            <span :class="{'text-success': data.value > 0, 'text-danger': data.value < 0}">{{ toMoney(data.value || 0) }}</span>
          </template>
        </b-table>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <!-- แถบแรก -->
    <b-card no-body>
      <div class="card-content">
        <b-table
          :fields="fieldPromotion"
          :items="tablePromotion"
          responsive
          table-class="table-hover" show-empty>
          <template #empty="">
            <div class="align-items-center text-center">ไม่พบข้อมูล</div>
          </template>
          <template #emptyfiltered="">
            <div class="align-items-center text-center">ไม่พบข้อมูล</div>
          </template>
          <template #cell(amount)="data">
            <span :class="{'text-success': data.value > 0, 'text-danger': data.value < 0}">{{ toMoney(data.value || 0) }}</span>
          </template>
        </b-table>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BInputGroupAppend, BFormSelect,
  BPagination, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import func from '@core/comp-functions/misc/func'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'

export default {
  components: {
    BCard, BCardHeader, BCardBody,
    BCol, BRow, BTable,
    BInputGroup, BInputGroupPrepend, BFormInput, BFormGroup, BInputGroupAppend,
    BButton,
    BFormSelect,
    BPagination,
    BDropdown, BDropdownItem, BDropdownDivider,
    vSelect,
    flatPickr,
    AppEchartStackedArea,
  },
  data() {
    return {
      func: func,
      BASE_URL: process.env.BASE_URL,
      date_start_report: '',
      date_end_report: '',
      selected: { title: 'ทั้งหมด', value: '' },
      option: [
        { title: 'ทั้งหมด', value: '' },
      ],
      draw: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      totalRows: 50,
      currentPage: 5,
      recordsTotal: 0,
      filter: '',
      filterOn: [],
      fields: [
        { key: 'deposit', label: 'ฝาก', class: 'text-center' },
        { key: 'bonus', label: 'โบนัส', class: 'text-center' },
        { key: 'all', label: 'ฝาก+โบนัส', class: 'text-center' },
        { key: 'withdraw', label: 'ถอน', class: 'text-center' },
        { key: 'total', label: 'กำไรขาดทุน', class: 'text-center' },
      ],
      items: [
        { deposit: 0, bonus: 0, all: 0, withdraw: 0, total: 0 },
      ],
      fieldPromotion: [
        { key: 'name', label: 'รายการโบนัส' },
        { key: 'amount', label: 'โบนัส', class: 'text-right' },
      ],
      tablePromotion: [
        { name: 'โบนัสคืนยอดเสีย', amount: 0 },
        { name: 'รวมโบนัส', amount: 0 },
      ],
      option_member: {
        xAxisData: ['สมาชิกใหม่', 'สมาชิกใหม่ที่รับเครดิตฟรี', 'สมาชิกที่มีการฝากทั้งหมด', 'รับเครดิตฟรีและมีการฝาก', 'สมาชิกใหม่ฝากครั้งแรก', 'สมาชิกเก่า ที่มีการฝาก'],
        series: [
          {
            name: 'จำนวนสมาชิก',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            showSymbol: true,
            lineStyle: {
              width: 2,
            },
            data: [1149, 0, 843, 0, 836, 0],
          },
        ],
      },
      option_deposit: {
        xAxisData: ['ยอดฝากทั้งหมด', 'สมาชิกใหม่ฝากครั้งแรก', 'รับเครดิตฟรีและฝากครั้งแรก', 'สมาชิกเก่า', 'โบนัสเครดิตฟรี'],
        series: [
          {
            name: 'ยอดฝาก',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            showSymbol: true,
            lineStyle: {
              width: 2,
            },
            data: [3840502, 825031, 0, 3021054, 0],
          },
        ],
      },
      t_fetch: 0,
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  mounted() {
    this.totalRows = this.items.length
    // this.fetch()
    // this.fetch_prefix()
    // this.t_fetch = setInterval(() => {
    //   this.fetch()
    // }, 1000 * 60)
  },
  watch: {
    filter: {
      handler() {
        this.fetch()
        this.totalRows = this.items.length
        this.currentPage = 1
      },
    }
  },
  methods: {
    toMoney(value) {
      const parts = parseFloat(value).toFixed(2).split('.')
      return parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? `.${parts[1]}` : '')
    },
    toNum(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    onFiltered(filteredItems) {
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    onPageSelect() {
      let start = this.perPage * (this.currentPage - 1)
      this.fetch(start)
    },
    onPageChange(page) {
      let start = this.perPage * (page - 1)
      this.fetch(start)
    },
    load(data) {
      if (data == null || data.data == null) return;
      this.draw = data.draw
      this.items = data.data
      this.totalRows = data.recordsFiltered
      this.recordsTotal = data.recordsTotal
    },
    fetch(start=0) {
      let url = `ref/ref_list_page?draw=${this.draw}&start=${start}&length=${this.perPage}&search%5Bvalue%5D=${this.filter}&prefix=${this.selected.value}&date_start=${this.date_start_report}&date_end=${this.date_end_report}&status=&_=${Date.now()}`;
      this.$http.get(url).then(res => { this.load(res.data) })
    },
    fetch_prefix() {
      this.$http.get('ref/prefix').then(res => { res.data.forEach(data=> this.option.push({title: data.prefix, value: data.prefix}))})
    },
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.t_fetch)
    next()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.vs__dropdown-toggle, .vs__dropdown-toggle:hover { border-top-left-radius: 0; border-bottom-left-radius: 0; }
</style>
